import React from 'react';
import Layout from '@/layouts/main';
import PrivacyPolicy from '@/views/privacy-policy';
import { Mixpanel } from '@/utils';

export default function OurPrivacyPolicy() {
  React.useEffect(() => Mixpanel.track(`View Privacy Policy Page`), []);
  return (
    <Layout
      title="Privacy Policy - Transforming mental health through the gut"
      description="We aim to reduce over-dependence on pills by using good food to better the health
outcomes of people living with anxiety and depression."
      active="privacy-policy"
    >
      <PrivacyPolicy />
    </Layout>
  );
}
